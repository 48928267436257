'use strict';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

const platform_settings = JSON.parse(
  document.getElementById("platform-settings")?.textContent ?? "{}"
)

if (platform_settings.sentry) {
  const initialScope = {};
  if (platform_settings.sentry.send_pii && platform_settings.user) {
    initialScope.user = platform_settings.user;
  }
  Sentry.init({
    dsn: 'https://af61f53578234dfb98cd89e6c96bc098@o392755.ingest.sentry.io/6105198',
    environment: platform_settings.sentry.environment,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.05,
    initialScope,
  });
}
