'use strict';

import 'bootstrap/js/dist/modal';
import bootbox from 'bootbox';
import Cookies from 'js-cookie';

const platform_settings = JSON.parse(document.getElementById("platform-settings")?.textContent ?? "{}");
const csrf_cookie = Cookies.get(platform_settings.csrf_cookie_name ?? "csrftoken");

export function hijack() {
  bootbox.prompt('Username:', function(username) {
    if (!username) {
      return;
    }

    const data = new FormData();
    data.append("user_pk", username);

    fetch("/hijack/acquire/", {
        method: 'POST',
        headers: {'X-CSRFToken': csrf_cookie},
        body: data,
      })
      .then((response) => {
        if (response.ok) {
          window.location.reload(true);
          return;
        }
        let msg;
        if (response.status === 404) {
          msg = "Unable to find user. Please check that the username is valid."
        }
        else if(response.status === 403) {
          msg = "You do not have permission to login as this user."
        }
        else {
          msg = "Something went wrong. Please try again."
        }
        bootbox.alert(msg);
      })
      .catch(() => bootbox.alert("Something went wrong. Please try again."));
  });
}
