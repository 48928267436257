'use strict';
import { hijack } from './hijack';

document.addEventListener('DOMContentLoaded', function(e) {
  const adminItemsContainer = document.getElementById('admin-menu-items');
  
  if (adminItemsContainer === null) {
    return;
  }

  fetch('/api/admin-menu/').then(res => {
    if (res.status === 401) {
      return;
    }
    res.json().then(response => {
      const links = response.menu.map(item => `<a class="dropdown-item" href="${item.link || '#'}" data-action="${item.action || ''}">${item.title}</a>`);
      adminItemsContainer.innerHTML = links.join("");
      adminItemsContainer.querySelector('[data-action="login_as"]')?.addEventListener('click', hijack);
    })
  });
});
