'use strict';

import { basemapLayer } from 'esri-leaflet';

const MapUtils = {
    IMAGERY_LABELS_KEY: 'Esri Imagery Labels',
};

MapUtils.add_esri_basemap = (map) => {
  let esri_imagery = basemapLayer('Imagery', {
    maxNativeZoom: 17,
    maxZoom: 21,
    zIndex: -1000,
  });
  esri_imagery.addTo(map);
};

export default MapUtils;
