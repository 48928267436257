"use strict";

import mixpanel from 'mixpanel-browser';
import m from 'mithril';

function setup_mixpanel() {
  const settings_el = document.getElementById("platform-settings");
  const platform_settings = JSON.parse(settings_el ? settings_el.textContent : "{}");
  if (!platform_settings.mixpanel_api_token) {
    // Initialise and disable mixpanel, so that it can still be called
    mixpanel.init('false');
    mixpanel.disable();
    return;
  }

  mixpanel.init(platform_settings.mixpanel_api_token);

  const get_whoami = () => {
    return m.request({
      url: '/api/whoami/',
      method: 'GET',
    });
  }
  // mixpanel auth and user setup
  get_whoami()
    .then(
      (res) => {
        let profile = res;
        if (profile.is_hijacked) {
          mixpanel.disable();
          return;
        }
        // Note - we should only be calling identify when user first logs in, 
        // not every time this is run.
        // This has been fixed in the angular login flow, but has been left as-is here
        // in the hope that we can decommission this flow in due course.
        mixpanel.identify(profile.id.toString());
        mixpanel.people.set({
          $email: profile.email,
          $name: profile.display_name,
          Organisation: profile.orgs,
        });
        mixpanel.people.set_once({ 'First login': new Date() });
      },
      (error) => {
        console.error("Failed to get user information");
      }
    )
}

setup_mixpanel();