'use strict';

import 'fontawesome';
import $ from 'jquery';
import m from 'mithril';

import 'bootstrap/js/dist/tooltip';

import { ProjectMap } from './components/project-map.js';


function setup_maps() {
  $('.project__map-container').each((idx, el) => {
    const project_pk = $(el).data('project-pk');
    m.mount(el, {view: () => m(ProjectMap, {'project_pk': project_pk})});
  });
}

$(() => {
  setup_maps();

  $('[data-toggle="tooltip"]').tooltip();
});
